<template>
  <StyledModal :visible="visible" :title="`${isAdd ? '新建' : '编辑'}科普`" @close="close" @submit="submit" :submitLoading="submitLoading" width="700px">
    <el-form class="form_box" ref="formRef" :model="formModel.form" label-width="120px">
      <el-form-item label="科普名称" prop="templateDataName" :rules="[{ required: true, message: '请输入科普名称' }]">
        <el-input v-model="formModel.form.templateDataName" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="是否默认" prop="ifDefault" :rules="[{ required: true }]">
        <el-radio-group v-model="formModel.form.ifDefault">
          <el-radio label="0">否</el-radio>
          <el-radio label="1">是</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </StyledModal>
</template>

<script>
import { reactive, ref, computed } from 'vue'
import { cloneDeep } from 'lodash'
import StyledModal from '@/components/modal/StyledModal/index.vue'
import ImgUpload from '@/components/ImgUpload'
import FetchSelect from '@/components/FetchSelect'
import DetailEditor from '@/components/DetailEditor'
import { validateFormRef } from '@/common/utils_element'
import request from '@/utils/request'
import { trimAll } from '@/utils/utils'

const defaultForm = {
  templateDataName: '',
  ifDefault: '0', // 提交的是number  返回的是 string
}

export default {
  emits: ['successAdd', 'successEdit'],
  setup(_, ctx) {
    const visible = ref(false)
    const submitLoading = ref(false)

    const formRef = ref(null)
    const formModel = reactive({
      form: cloneDeep(defaultForm),
    })

    const record = reactive({ v: null })
    const isAdd = computed(() => (record.v ? false : true))

    function open(recordParam) {
      visible.value = true
      if (recordParam) {
        record.v = cloneDeep(recordParam)
        renderForm(cloneDeep(recordParam))
      }
    }

    function close() {
      visible.value = false
      record.v = null
      formModel.form = cloneDeep(defaultForm)
    }

    // form回显
    function renderForm(data) {
      formModel.form = {
        ...data,
      }
    }

    // 提交
    async function submit() {
      await validateFormRef(formRef)

      const postData = {
        ...(isAdd.value ? {} : { templateCode: record.v.templateCode }),
        ...formModel.form,
      }
      submitLoading.value = true
      console.log('🚀 ~ postData', postData)
      request({
        url: isAdd.value ? '/web/system/uiTemplateData/createUiTemplateData' : '/web/system/uiTemplateData/updateUiTemplateData',
        data: postData,
      })
        .finally(() => {
          submitLoading.value = false
        })
        .then(() => {
          ctx.emit(isAdd.value ? 'successAdd' : 'successEdit')
          close()
        })
    }

    return {
      visible,
      isAdd,
      submitLoading,
      open,
      close,
      submit,
      //
      formRef,
      formModel,
    }
  },
  components: { StyledModal, FetchSelect, ImgUpload, DetailEditor },
}
</script>

<style lang="less">
.input_refTargetUrl {
  textarea {
    min-height: 170px !important;
  }
}
</style>

<style lang="less" scoped>
.form_box {
  width: 90%;
}
</style>
